import React from 'react'
import styles from './about.module.css'

import Layout from '../components/layout'

const AlmostPage = () => (
  <Layout>
    <div className={styles.root}>
      <h1>Almost ready...</h1>
      <p>Go to your inbox and click the confirmation link I just emailed you.</p>

    </div>
  </Layout>
)

export default AlmostPage
